
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-about::v-deep {
    background-color: $white;
    color: $black;

    .reseller_image {
        width: 270px;
        height: 270px;

        display: block;
        margin: 0 auto 1em;
        overflow: hidden;

        &.empty {
            border-radius: 50%;
            background: linear-gradient(
                -45deg,
                #3060bd,
                #aaebff 50%,
                white 49%,
                white 51%,
                #3060bd 51%
            );
        }
    }

    .about {
        font-size: 110%;

        p {
            padding-bottom: 1em;
        }
    }
}
