
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.customer-testimonials::v-deep {
    max-width: 1100px;
    overflow: visible;

    .position {
        font-size: 23px;
    }

    .v-item--active.v-btn--active.v-btn--icon {
        color: $darker-blue;
    }

    .v-btn--icon {
        color: $grey-medium-light;
    }

    .quote-icon {
        width: 55px;
        height: 60px;
        border-bottom: 5px solid $primary-color;
    }
}
